.navbar__large {
  color: #850808;
  font-style: bold;
  font-size: 1.5rem;
}

.navbar__home {
  color: #5d534a;
  font-style: bold;
}
