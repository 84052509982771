@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@200;400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sanx m-0
  

  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
